<template>
	<a-config-provider :locale="locale">
		<div id="app">
			<router-view />
			<ws-loading v-if="$store.state.loading"></ws-loading>
		</div>
	</a-config-provider>
</template>

<script type="text/javascript">
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import wsLoading from '@/components/wsLoading.vue';

export default {
	components: {
		wsLoading
	},
	data() {
		return {
			locale: zhCN
		};
	}
};
</script>

<style lang="less">
@import '~ant-design-vue/dist/antd.less';
@import url('~@/assets/less/common.less');

:root {
  --primary-color: #3E4454;
  --active-bgcolor: #3E4454;
}
</style>